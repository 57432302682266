// 公共搜索组件块
import SearchContainer from './searchContainer'
// 公共操作按钮组件块
import ButtonContainer from './buttonContainer'
// 公共表格组件块
import TableContainer from './tableContainer'
// 公共分页组件块
import PaginationContainer from './paginationContainer'
const plugins = {
  install: function (Vue) {
    Vue.component('VSearch', SearchContainer)
    Vue.component('VButton', ButtonContainer)
    Vue.component('VTable', TableContainer)
    Vue.component('VPagination', PaginationContainer)
  }
}

export default plugins
