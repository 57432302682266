import Vue from 'vue'
import Router from 'vue-router'
import $store from '@/store'

/* Layout */
import Layout from '@/layout'
Vue.use(Router)

export const constantRoutes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/view/login/login.vue')
  },
  {
    path: '/memberUserInfo',
    name: 'memberUserInfo',
    component: () => import('@/view/member/userInfo'),
    meta: { title: '修改密码', auth: true }
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '首页', auth: true },
    children: [
    {
      name: 'managementNotice',
      path: '/managementNotice',
      component: () => import('@/view/management/notice'),
      meta: { title: '平台公告', auth: true }
    }, {
      name: 'memberList',
      path: '/memberList',
      component: () => import('@/view/member/member'),
      meta: { title: '会员管理', auth: true }
    }, {
      name: 'memberOnline',
      path: '/memberOnline',
      component: () => import('@/view/member/memberOnline'),
      meta: { title: '在线人数', auth: true }
    },
    {
      name: 'masterHandle',
      path: '/masterHandle',
      component: () => import('@/view/member/masterHandle'),
      meta: { title: '高手榜', auth: true }
    }, {
      name: 'comment_new_list',
      path: '/comment_new_list',
      component: () => import('@/view/member/comment_new_list'),
      meta: { title: '评论管理', auth: true }
    }, {
      name: 'memberIntegrateList',
      path: '/memberIntegrateList',
      component: () => import('@/view/member/integrateList'),
      meta: { title: '积分记录', auth: true }
    }, {
      name: 'memberFeedBack',
      path: '/memberFeedBack',
      component: () => import('@/view/member/feedBack'),
      meta: { title: '意见反馈', auth: true }
    }, {
      name: 'memberFeedBackDetails',
      path: '/memberFeedBackDetails',
      component: () => import('@/view/member/feedBackDetails'),
      meta: { title: '意见反馈', auth: true }
    },
    {
      name: 'memberFeedBackType',
      path: '/memberFeedBackType',
      component: () => import('@/view/member/memberFeedBackType'),
      meta: { title: '反馈主题', auth: true }
    },
    {
      name: 'memberLogList',
      path: '/memberLogList',
      component: () => import('@/view/member/logList'),
      meta: { title: '登录记录查询', auth: true }
    }, {
      name: 'memberPostList',
      path: '/memberPostList',
      component: () => import('@/view/member/postList'),
      meta: { title: '帖子管理', auth: true }
    }, {
      name: 'memberNoticeList',
      path: '/memberNoticeList',
      component: () => import('@/view/member/noticeList'),
      meta: { title: '系统消息', auth: true }
    }, {
      name: 'platformSiteDomainList',
      path: '/platformSiteDomainList',
      component: () => import('@/view/platform/siteDomainList'),
      meta: { title: '域名管理', auth: true }
    }, {
      name: 'platformSiteHelpList',
      path: '/platformSiteHelpList',
      component: () => import('@/view/platform/siteHelpList'),
      meta: { title: '帮助指南列表', auth: true }
    }, {
      name: 'platformAdvertis',
      path: '/platformAdvertis',
      component: () => import('@/view/platform/advertis'),
      meta: { title: '首页轮播', auth: true }
    }, {
      name: 'adList',
      path: '/adList',
      component: () => import('@/view/platform/adList'),
      meta: { title: '详情轮播', auth: true }
    }, {
      name: 'friendHandle',
      path: '/getFriendHandle',
      component: () => import('@/view/platform/friendHandle'),
      meta: { title: '链接管理', auth: true }
    }, {
      name: 'gameList',
      path: '/gameList',
      component: () => import('@/view/platform/gameList'),
      meta: { title: '游戏大厅轮播', auth: true }
    }, {
      name: 'platformPictureType',
      path: '/platformPictureType',
      component: () => import('@/view/platform/pictureType'),
      meta: { title: '图片类型', auth: true }
    }, {
      name: 'platformPicture',
        path: '/platformPicture',
        component: () => import('@/view/platform/picture'),
        meta: { title: '图片', auth: true }
    }, {
      name: 'platformExternal',
      path: '/platformExternal',
      component: () => import('@/view/platform/external'),
      meta: { title: '外接图片', auth: true }
    }, {
      name: 'masterBetList',
      path: '/masterBetList',
      component: () => import('@/view/platform/masterBetList'),
      meta: { title: '立即下注', auth: true }
    }, {
      name: 'upload_posts',
      path: '/upload_posts',
      component: () => import('@/view/platform/upload_posts'),
      meta: { title: '图片上传', auth: true }
    },
    {
      name: 'platformGalleryTypeNews',
      path: '/platform/galleryTypeNews',
      component: () => import('@/view/platform/galleryTypeNews'),
      meta: { title: '图库类型分类', auth: true }
    },
    {
      name: 'platformExplain',
      path: '/platform/explain',
      component: () => import('@/view/platform/explain'),
      meta: { title: '图解列表', auth: true }
    },
    {
      name: 'platformGalleryType',
      path: '/platformGalleryType',
      component: () => import('@/view/platform/galleryType'),
      meta: { title: '图库类型', auth: true }
    },
    {
      name: 'getMialList',
      path: '/getMialList',
      component: () => import('@/view/platform/getMialList'),
      meta: { title: '资料类型', auth: true }
    },
    {
      name: 'getMaterialList',
      path: '/getMaterialList',
      component: () => import('@/view/platform/getMaterialList'),
      meta: { title: '资料大全管理', auth: true }
    },
    {
      name: 'getFormula',
      path: '/getFormula',
      component: () => import('@/view/platform/getFormula'),
      meta: { title: '六合公式', auth: true }
    },
    {
      name: 'getHumor',
      path: '/getHumor',
      component: () => import('@/view/platform/getHumor'),
      meta: { title: '幽默猜测', auth: true }
    },
    {
      name: 'postDiscovery',
      path: '/postDiscovery',
      component: () => import('@/view/member/postDiscovery'),
      meta: { title: '发现管理', auth: true }
    },
    {
      name: 'IpBlacklist',
      path: '/IpBlacklist',
      component: () => import('@/view/member/IpBlacklist'),
      meta: { title: 'IP黑名单', auth: true }
    },
    {
      name: 'IpWhitelist',
      path: '/IpWhitelist',
      component: () => import('@/view/member/IpWhitelist'),
      meta: { title: 'IP白名单', auth: true }
    },
    {
      name: 'getRechargeCoin',
      path: '/getRechargeCoin',
      component: () => import('@/view/member/getRechargeCoin'),
      meta: { title: '码币充值记录', auth: true }
    },
    {
      name: 'chat',
      path: '/chat',
      component: () => import('@/view/chat/chat'),
      meta: { title: '聊天室管理', auth: true }
    },
    {
      name: 'chatImage',
      path: '/chatImage',
      component: () => import('@/view/chat/chatImage'),
      meta: { title: '聊天室图片审核', auth: true }
    },
    {
      name: 'chatJumpUrl',
      path: '/chat/chatJumpUrl',
      component: () => import('@/view/chat/url'),
      meta: { title: '聊天室转跳url', auth: true }
    },
    {
      name: 'chatRoomMessage',
      path: '/chatRoomMessage',
      component: () => import('@/view/chat/chatRoomMessage'),
      meta: { title: '聊天记录', auth: true }
    },
    {
      name: 'getPanningCodeFlowList',
      path: '/getPanningCodeFlowList',
      component: () => import('@/view/panning/getPanningCodeFlowList'),
      meta: { title: '码币记录', auth: true }
    },
    {
      name: 'commentsuser',
      path: '/commentsuser',
      component: () => import('@/view/member/commentsuser'),
      meta: { title: '后台评论用户', auth: true }
    },
    {
      name: 'tip',
      path: '/jubao',
      component: () => import('@/view/member/tip'),
      meta: { title: '举报', auth: true }
    },
    {
      name: 'integrateTypeList',
      path: '/integrateTypeList',
      component: () => import('@/view/activity/typeList'),
      meta: { title: '积分类型', auth: true }
    }, {
      name: 'lotterySet',
      path: '/lotterySet',
      component: () => import('@/view/lottery/lotterySet'),
      meta: { title: '彩种管理', auth: true }
    }, {
      name: 'platformLotteryData',
      path: '/platformLotteryData',
      component: () => import('@/view/lottery/platformLotteryData'),
      meta: { title: '开奖日期', auth: true }
    }, {
      name: 'platformDrawManagement',
      path: '/platformDrawManagement',
      component: () => import('@/view/lottery/drawManagement'),
      meta: { title: '开奖管理', auth: true }
    }, {
      name: 'lotteryOpen',
      path: '/lotteryOpen',
      component: () => import('@/view/lottery/lotteryOpen'),
      meta: { title: '开奖报奖', auth: true }
    }, {
      name: 'all_kjtime',
      path: '/all_kjtime',
      component: () => import('@/view/lottery/all_kjtime'),
      meta: { title: '开奖报奖', auth: true }
    }, {
      name: 'start_page',
      path: '/start_page',
      component: () => import('@/view/platform/start_page'),
      meta: { title: '开奖报奖', auth: true }
    }, {

      name: 'wnDataImage',
      path: '/wnDataImage',
      component: () => import('@/view/lottery/wnDataImage'),
      meta: { title: '开奖文字管理', auth: true }

    },
    {
      name: 'wnStreamList',
      path: '/wnStreamList',
      component: () => import('@/view/lottery/wnStreamList'),
      meta: { title: '开奖直播', auth: true }
    },
    {
      name: 'reportList',
      path: '/reportList',
      component: () => import('@/view/platform/reportList'),
      meta: { title: '举报', auth: true }
    }, {
      name: 'recommended',
      path: '/recommended',
      component: () => import('@/view/platform/recommended'),
      meta: { title: '推荐开奖', auth: true }
    }, {
      name: 'topic',
      path: '/topic',
      component: () => import('@/view/platform/topic'),
      meta: { title: '活动管理', auth: true }
    }, {
      name: 'getShartUrl',
      path: '/getShartUrl',
      component: () => import('@/view/platform/getShartUrl'),
      meta: { title: '活动规则', auth: true }
    },
    {
      name: 'activityManageActivity',
      path: '/activityManageActivity',
      component: () => import('@/view/activity/manageActivity'),
      meta: { title: '商品管理', auth: true }

    }, {
      name: 'activityActivityType',
      path: '/activityActivityType',
      component: () => import('@/view/activity/activityType'),
      meta: { title: '商品类型', auth: true }
    }, {
      name: 'activitylogActivityLog',
      path: '/activitylogActivityLog',
      component: () => import('@/view/activity/activityLog'),
      meta: { title: '积分兑换记录', auth: true }
    }, {
      name: 'memberInformation',
      path: 'membernew/list',
      component: () => import('@/view/member/memberInformation'),
      meta: { title: '会员信息', auth: true }
    },
    {
      name: 'phoneCode',
      path: 'membernew/phoneCode',
      component: () => import('@/view/member/phoneCode'),
      meta: { title: '短信列表', auth: true }
    },
    {
      name: 'memberInformation2',
      path: 'membernew/list2',
      component: () => import('@/view/member/memberInformation2'),
      meta: { title: '会员信息', auth: true }
    }, {
      name: 'manualWithdrawal',
      path: 'membernew/balance',
      component: () => import('@/view/member/manualWithdrawal'),
      meta: { title: '人工提存', auth: true }
    }, {
      name: 'withDrawalList',
      path: '/withdraw',
      component: () => import('@/view/member/withDrawalList'),
      meta: { title: '提现列表', auth: true }
    }, {
      name: 'getRecharge',
      path: '/getRecharge',
      component: () => import('@/view/member/getRecharge'),
      meta: { title: '充值记录', auth: true }
    }, {
      name: 'memberPriceDetail',
      path: '/memberPriceDetail',
      component: () => import('@/view/member/memberPriceDetail'),
      meta: { title: '会员资金表动明细', auth: true }
    }, {
      name: 'gameRecharge',
      path: '/gameRecharge',
      component: () => import('@/view/member/gameRecharge'),
      meta: { title: '额度转换记录', auth: true }
    }, {
      name: 'getRebateStatistics',
      path: '/getRebateStatistics',
      component: () => import('@/view/member/rebateStatistics'),
      meta: { title: '彩票返利', auth: true }
    },
    {
      name: 'getMemberPromotion',
      path: '/getMemberPromotion',
      component: () => import('@/view/member/getMemberPromotion'),
      meta: { title: '查看邀请码', auth: true }
    },
    {
      name: 'getMemberProxy',
      path: '/getMemberProxy',
      component: () => import('@/view/member/getMemberProxy'),
      meta: { title: '查看下级', auth: true }
    },
    {
      name: 'customer',
      path: '/customer',
      component: () => import('@/view/member/customer'),
      meta: { title: '客服账号', auth: true }
    },
    {
      name: 'getGame',
      path: '/getGame',
      component: () => import('@/view/member/getGame'),
      meta: { title: '游戏列表', auth: true }
    }, {
      name: 'gamePlatformData',
      path: '/gamePlatformData',
      component: () => import('@/view/member/gamePlatformData'),
      meta: { title: '注单查询', auth: true }
    }, {
      name: 'getGameType',
      path: '/getGameType',
      component: () => import('@/view/member/getGameType'),
      meta: { title: '游戏类型列表', auth: true }
    }, {
      name: 'getGameRegister',
      path: '/getGameRegister',
      component: () => import('@/view/member/getGameRegister'),
      meta: { title: '游戏注册列表', auth: true }
    }, {
      name: 'getGameChannel',
      path: '/getGameChannel',
      component: () => import('@/view/member/getGameChannel'),
      meta: { title: '游戏通道列表', auth: true }
    }, {
      name: 'getGameChannelPerson',
      path: '/getGameChannelPerson',
      component: () => import('@/view/member/getGameChannelPerson'),
      meta: { title: '游戏通道列表', auth: true }
    },
    {
      name: 'gameClassificationList',
      path: '/gameClassificationList',
      component: () => import('@/view/member/gameClassificationList'),
      meta: { title: '游戏平台列表', auth: true }
    }, {
      name: 'real',
      path: '/real',
      component: () => import('@/view/member/real'),
      meta: { title: '用户真实信息', auth: true }
    }, {
      name: 'auditUser',
      path: '/auditUser',
      component: () => import('@/view/member/auditUser'),
      meta: { title: '用户提现账号', auth: true }
    }, {
      name: 'promotionsDetailList',
      path: '/promotionsDetailList',
      component: () => import('@/view/member/promotionsDetailList'),
      meta: { title: '优惠活动列表', auth: true }
    }, {
      name: 'promotionsList',
      path: '/promotionsList',
      component: () => import('@/view/member/promotionsList'),
      meta: { title: '优惠活动类型', auth: true }
    }, {
      name: 'memberHeaderInfo',
      path: '/getHeadInfo',
      component: () => import('@/view/member/memberHeaderInfo'),
      meta: { title: '头像审核列表', auth: true }
    }, {
      name: 'disCountList',
      path: '/disCountList',
      component: () => import('@/view/member/disCountList'),
      meta: { title: '充值返利', auth: true }
    },
    {
      name: 'distribute',
      path: '/master/distribute',
      component: () => import('@/view/member/distribute'),
      meta: { title: '派发管理', auth: true }
    },
    {
      name: 'level',
      path: '/level',
      component: () => import('@/view/member/level'),
      meta: { title: '层级列表', auth: true }
    },
    {
      name: 'memberActivity',
      path: '/member/memberActivity',
      component: () => import('@/view/member/activity/list.vue'),
      meta: { title: '活动管理', auth: true }
    },
    {
      name: 'userActivityList',
      path: '/member/userActivityList',
      component: () => import('@/view/member/activity/userList.vue'),
      meta: { title: '用户活动列表', auth: true }
    },
    {
      name: 'payChannelList',
      path: '/member/payChannelList',
      component: () => import('@/view/member/payment/channel.vue'),
      meta: { title: '支付渠道', auth: true }
    },
    {
      name: 'payRechargeList',
      path: '/member/payRechargeList',
      component: () => import('@/view/member/payment/order.vue'),
      meta: { title: '充值订单', auth: true }
    },
    {
      name: 'payWithdrawMemberCard',
      path: '/member/payWithdrawMemberCard',
      component: () => import('@/view/member/payment/bankCard.vue'),
      meta: { title: '银行卡列表', auth: true }
    },
    {
      name: 'payWithdrawList',
      path: '/member/payWithdrawList',
      component: () => import('@/view/member/payment/withdrawal.vue'),
      meta: { title: '提现列表', auth: true }
    },
    {
      name: 'payChannlel',
      path: '/member/payChannlel',
      component: () => import('@/view/member/payment/methods.vue'),
      meta: { title: '支付方式', auth: true }
    },
    {
      path: '/quiz',
      name: '竞猜管理',
      component: () => import('@/view/guessing/guessing'),
      meta: { title: '竞猜管理', auth: true }

    }, {
      path: '/QuizChoose',
      name: '竞猜选项列表',
      component: () => import('@/view/guessing/guessingOptions'),
      meta: { title: '竞猜选项列表', auth: true }

    }]
  },

  /** 系统管理（用户管理） */
  {
    path: '/',
    component: Layout,
    meta: { title: '用户管理', auth: true },
    children: [
      {
        name: 'adminLog',
        path: '/userAdmin/adminLog',
        component: () => import('@/view/userAdmin/adminLog'),
        meta: { title: '管理员日志', auth: true }
      }, {
        name: 'adminList',
        path: '/userAdmin/adminList',
        component: () => import('@/view/userAdmin/adminList'),
        meta: { title: '管理员列表', auth: true }
      }, {
        name: 'adminRole',
        path: '/userAdmin/adminRole',
        component: () => import('@/view/userAdmin/adminRole'),
        meta: { title: '角色列表', auth: true }
      }
    ]
  },

  /** 系统管理（网站管理） */
  {
    path: '/',
    component: Layout,
    meta: { title: '网站管理', auth: true },
    children: [
      {
        name: 'mysteryTipsbagExplain',
        path: '/master/mysteryTipsbagExplain',
        component: () => import('@/view/website/mysteryTipsbagExplain'),
        meta: { title: '玄机锦囊', auth: true }
      },
      {
        name: 'popupWindow',
        path: '/website/popupWindow',
        component: () => import('@/view/website/popupWindow'),
        meta: { title: '弹窗管理', auth: true }
      }, {
        name: 'blackRoom',
        path: '/website/blackRoom',
        component: () => import('@/view/website/blackRoom'),
        meta: { title: '小黑屋管理', auth: true }
      }, {
        name: 'notChatTime',
        path: '/website/notChatTime',
        component: () => import('@/view/website/notChatTime'),
        meta: { title: '禁言时间管理', auth: true }
      }, {
        name: 'notChatReason',
        path: '/website/notChatReason',
        component: () => import('@/view/website/notChatReason'),
        meta: { title: '禁言原因管理', auth: true }
      }, {
        name: 'activity',
        path: '/website/activity',
        component: () => import('@/view/website/activity'),
        meta: { title: '分享链接', auth: true }
      }, {
        name: 'bywz',
        path: 'platform/bywz',
        component: () => import('@/view/platform/bywz.vue'),
        meta: { title: '备用网址', auth: true }
      }, {
        name: 'about',
        path: '/website/about',
        component: () => import('@/view/website/about'),
        meta: { title: '关于我们列表', auth: true }
      }, {
        name: 'kefu',
        path: '/website/kefu',
        component: () => import('@/view/website/kefu'),
        meta: { title: '客服管理', auth: true }
      }, {
        name: 'exchangeGroup',
        path: '/website/exchangeGroup',
        component: () => import('@/view/website/exchangeGroup'),
        meta: { title: '交流群二维码', auth: true }
      }, {
        name: 'years',
        path: '/website/years',
        component: () => import('@/view/website/years'),
        meta: { title: '年份管理', auth: true }
      }, {
        name: 'setting',
        path: '/website/setting',
        component: () => import('@/view/website/setting'),
        meta: { title: '基础配置', auth: true }
      }, {
        name: 'words',
        path: '/website/words',
        component: () => import('@/view/website/words'),
        meta: { title: '敏感词管理', auth: true }
      }, {
        name: 'avatar',
        path: '/website/headInfo',
        component: () => import('@/view/website/avatar'),
        meta: { title: '头像管理', auth: true }
      }, {
        name: 'getIllustrate',
        path: '/website/getIllustrate',
        component: () => import('@/view/website/getIllustrate'),
        meta: { title: '网站说明', auth: true }
      }, {
        name: 'attributes',
        path: '/website/attributes',
        component: () => import('@/view/website/attributes'),
        meta: { title: '号码属性', auth: true }
      },
      {
        name: 'instruction',
        path: '/instruction',
        component: () => import('@/view/website/instruction'),
        meta: { title: '帮助说明', auth: true }
      }
    ]
  },

  /** 系统管理（银行相关） */
  {
    path: '/',
    component: Layout,
    meta: { title: '银行相关', auth: true },
    children: [
      {
        name: 'bankList',
        path: '/bank/bankList',
        component: () => import('@/view/bank/bankList'),
        meta: { title: '银行列表', auth: true }
      },
      {
        name: 'banPaykList',
        path: '/bank/banPayList',
        component: () => import('@/view/bank/banPayList'),
        meta: { title: '收款银行列表', auth: true }
      },
      {
        name: 'zfbsk',
        path: '/bank/zfbsk',
        component: () => import('@/view/bank/zfbsk'),
        meta: { title: '支付宝收款渠道', auth: true }
      },
      {
        name: 'wxsk',
        path: '/bank/wxsk',
        component: () => import('@/view/bank/wxsk'),
        meta: { title: '微信收款渠道', auth: true }
      },
      {
        name: 'kzk',
        path: '/bank/kzk',
        component: () => import('@/view/bank/kzk'),
        meta: { title: '第三方转卡', auth: true }
      },
      {
        name: 'qqsk',
        path: '/qqsk',
        component: () => import('@/view/bank/qqsk'),
        meta: { title: 'qq收款渠道', auth: true }
      },
      {
        name: 'payConfig',
        path: 'payConfig',
        component: () => import('@/view/bank/payConfig'),
        meta: { title: '层级支付设定', auth: true }
      },
      {
        name: 'usdt',
        path: 'usdt',
        component: () => import('@/view/bank/usdt'),
        meta: { title: 'USDT渠道列表', auth: true }
      },
      {
        name: 'Cgpay',
        path: 'Cgpay',
        component: () => import('@/view/bank/Cgpay'),
        meta: { title: 'Cgpay渠道', auth: true }
      },
      {
        name: 'yhkdf',
        path: '/bank/yhkdf',
        component: () => import('@/view/bank/yhkdf'),
        meta: { title: '银行卡代付渠道', auth: true }
      },
      {
        name: 'bankPayWay',
        path: 'bankPayWay',
        component: () => import('@/view/bank/bankPayWay'),
        meta: { title: '支付提现通道', auth: true }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '报表查询', auth: true },
    children: [
      {
        name: 'report',
        path: '/data/report',
        component: () => import('@/view/report/report'),
        meta: { title: '个人报表', auth: true }
      },
      {
        name: 'summary',
        path: '/data/summary',
        component: () => import('@/view/report/summary'),
        meta: { title: '出入款账目汇总', auth: true }
      },
      {
        name: 'operation',
        path: '/data/operation',
        component: () => import('@/view/report/operation'),
        meta: { title: '每日运营报表', auth: true }
      },
      {
        name: 'totalreport',
        path: '/data/totalreport',
        component: () => import('@/view/report/totalreport'),
        meta: { title: '报表总概', auth: true }
      }
    ]
  },
  // 淘料管理
  {
    path: '/',
    component: Layout,
    meta: { title: '淘料管理', auth: true },
    children: [
      {
        name: 'exchange',
        path: '/exchange',
        component: () => import('@/view/panning/codeExchangeList'),
        meta: { title: '兑换管理', auth: true }
      },
      {
        name: 'userExchangeRecord',
        path: '/userExchangeRecord',
        component: () => import('@/view/panning/userExchangeRecord'),
        meta: { title: '用户兑换列表', auth: true }
      },
      {
        name: 'panningType',
        path: '/panningType',
        component: () => import('@/view/panning/panningType'),
        meta: { title: '淘料分类', auth: true }
      },
      {
        name: 'panningTypeInfo',
        path: '/panningTypeInfo',
        component: () => import('@/view/panning/panningTypeInfo'),
        meta: { title: '码内容', auth: true }
      },
      {
        name: 'getPanningZiTypeInfo',
        path: '/getPanningZiTypeInfo',
        component: () => import('@/view/panning/getPanningZiTypeInfo'),
        meta: { title: '子分类', auth: true }
      },
      {
        name: 'getExpertList',
        path: '/getExpertList',
        component: () => import('@/view/panning/getExpertList'),
        meta: { title: '竞猜列表', auth: true }
      },
      {
        name: 'getExpertApply',
        path: '/getExpertApply',
        component: () => import('@/view/panning/getExpertApply'),
        meta: { title: '竞猜列表', auth: true }
      }, {
        name: 'panningList',
        path: '/getPanningListr',
        component: () => import('@/view/panning/panningList'),
        meta: { title: '淘料列表', auth: true }
      },
      {
        name: 'getQuizOrderList',
        path: '/getQuizOrderList',
        component: () => import('@/view/panning/getQuizOrderList'),
        meta: { title: '竞猜列表', auth: true }
      },
      {
        name: 'getPanningMenu',
        path: '/getPanningMenu',
        component: () => import('@/view/panning/getPanningMenu'),
        meta: { title: '淘料菜单', auth: true }
      }
    ]
  },
  {
    name: 'error',
    path: '/404',
    component: () => import('@/view/404')
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  mode: 'hash', // history hash 后端支持可开 /operator/
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  const { auth } = to.meta
  to.matched.some((r) => {
    if (auth === true) {
      if ($store.getters.isLogin) {
        next()
      } else {
        next({ name: 'login', params: { path: r.path } })
      }
    } else {
      next()
    }
  })
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title + '-后台管理系统'
  }
  // keepAlive 缓存处理
  if (to.name !== from.name && to.meta.keepAlive) {
    // 判断目标路径和历史路径是否相同
    if (to.meta.lastHistoryFullPath && to.meta.lastHistoryFullPath === to.fullPath || !to.meta.lastHistoryFullPath) {
      // 相同则读取缓存数据
      to.meta.keepAliveChange = false
    } else if (to.meta.lastHistoryFullPath && to.meta.lastHistoryFullPath !== to.fullPath) {
      // 不同则重载
      to.meta.keepAliveChange = true
    }
  }
  to.meta.lastHistoryFullPath = true
})

export default router
