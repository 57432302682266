import $http from '@/utils/axios'

export function adminLog (data) {
  return $http.get('/platform/adminLog', data, { isLogin: true })
}
 
export function updAdminPwd (data) {
  return $http.get('/platform/updAdminPwd', data, { isLogin: true })
}
export function adminList (data) {
  return $http.get('/platform/adminList', data, { isLogin: true })
}

export function adminRoleList (data) {
  return $http.get('/platform/adminRoleList', data, { isLogin: true })
}

export function adminRoleListNew (data) {
  return $http.get('/platform/adminRoleListNew', data, { isLogin: true })
}

export function adminHandle (data) {
  return $http.get('/platform/adminHandle', data, { isLogin: true })
}

export function adminRoleHandle (data) {
  return $http.get('/platform/adminRoleHandle', data, { isLogin: true })
}

export function siteIpList (data) {
  return $http.get('/platform/siteIpList', data, { isLogin: true })
}

export function siteDomainList (data) {
  return $http.get('/platform/siteDomainList', data, { isLogin: true })
}

export function siteDomainHandle (data) {
  return $http.get('/platform/siteDomainHandle', data, { isLogin: true })
}

export function siteAboutList (data) {
  return $http.get('/platform/siteAboutList', data, { isLogin: true })
}

export function siteAboutHandle (data) {
  return $http.post('/platform/siteAboutHandle', data, { isLogin: true })
}

export function siteHelpList (data) {
  return $http.get('/platform/siteHelpList', data, { isLogin: true })
}

export function siteHelpHandle (data) {
  return $http.post('/platform/siteHelpHandle', data, { isLogin: true })
}

export function siteService (data) {
  return $http.get('/platform/siteService', data, { isLogin: true })
}

export function siteServiceEdit (data) {
  return $http.get('/platform/siteServiceEdit', data, { isLogin: true })
}

// 首页轮播列表
export function advertisList (data) {
  return $http.post('/platform/advertisList', data, { isLogin: true })
}

// （ 首页轮播，详情轮播、游戏大厅轮播 ）新增、删除、修改
export function advertisListHandle (data) {
  return $http.post('/platform/advertisListHandle', data, { isLogin: true })
}

// 详情轮播
export function postAdList (data) {
  return $http.post('/platform/adList', data, { isLogin: true })
}

// 游戏大厅轮播
export function gameList (data) {
  return $http.post('/platform/gameList', data, { isLogin: true })
}

export function pictureTypeList (data) {
  return $http.post('/platform/pictureTypeList', data, { isLogin: true })
}

export function pictureTypeHandle (data) {
  return $http.post('/platform/pictureTypeHandle', data, { isLogin: true })
}

export function getLotteryType (data) {
  return $http.post('/platform/getLotteryType', data, { isLogin: true })
}

export function pictureList (data) {
  return $http.post('/platform/pictureList', data, { isLogin: true })
}

export function pictureHandle (data) {
  return $http.post('/platform/pictureHandle', data, { isLogin: true })
}

export function externalList (data) {
  return $http.post('/platform/externalList', data, { isLogin: true })
}

export function externalHandle (data) {
  return $http.post('/platform/externalHandle', data, { isLogin: true })
}

export function yearsList (data) {
  return $http.post('/platform/yearsList', data, { isLogin: true })
}

export function yearsHandle (data) {
  return $http.post('/platform/yearsHandle', data, { isLogin: true })
}

export function batchImg (data) {
  return $http.post('/platform/batchImg', data, { isLogin: true })
}

export function batchExternalImg (data) {
  return $http.post('/platform/batchExternalImg', data, { isLogin: true })
}

export function getPictureType (data) {
  return $http.post('/platform/getPictureType', data, { isLogin: true })
}

export function getLottery (data) {
  return $http.post('/platform/getLottery', data, { isLogin: true })
}

export function siteGroup (data) {
  return $http.post('/platform/siteGroup', data, { isLogin: true })
}

export function galleryType (data) {
  return $http.post('/platform/galleryType', data, { isLogin: true })
}

// export function galleryType (data) {
//   return $http.post('/platform/updateBlackGalleryNew', data, { isLogin: true })
// }

export function galleryTypeHandle (data) {
  return $http.get('/platform/galleryTypeHandle', data, { isLogin: true })
}

// 推荐开奖
export function getRecommendList (data) {
  return $http.get('/recommend/recommend', data, { isLogin: true })
}

// 推荐开奖（ 新增 / 修改 ）
export function recommendHandle (data) {
  return $http.get('/recommend/recommendHandle', data, { isLogin: true })
}

// 玄机视频
export function postListVideo (data) {
  return $http.get('/member/postListVideo', data, { isLogin: true })
}

// 玄机视频 新增 / 修改 ）
export function postListVideoAdd (data) {
  return $http.get('/member/postListVideoAdd', data, { isLogin: true })
}

// 更新图库数据
export function pictureUpdate (data) {
  return $http.get('/platform/pictureUpdate', data, { isLogin: true })
}

// 基础设置
export function setting (data) {
  return $http.post('/platform/setting', data, { isLogin: true })
}

// 基础设置（修改）
export function updateSetting (data) {
  return $http.post('/platform/updateSetting', data, { isLogin: true })
}

// 图库上传2
export function updateBlackGallery (data) {
  return $http.post('/Platform/updateBlackGallery', data, { isLogin: true })
}

// 图解列表
export function galleryExplainList (data) {
  return $http.post('/platform/galleryExplainList', data, { isLogin: true })
}

// 图解操作
export function galleryExplain (data) {
  return $http.post('/platform/galleryExplain', data, { isLogin: true })
}

// 图库类型列表
export function galleryTypeNews (data) {
  return $http.post('/platform/galleryTypeNews', data, { isLogin: true })
}

// 图库类型操作
export function galleryTypeNewsExplain (data) {
  return $http.post('/platform/galleryTypeNewsExplain', data, { isLogin: true })
}

// 图库类型设置类型分类
export function galleryTypeSet (data) {
  return $http.post('/platform/galleryTypeSet', data, { isLogin: true })
}

// 图库类型设置URL
export function galleryJumpUrl (data) {
  return $http.post('/platform/galleryJumpUrl', data, { isLogin: true })
}

// 广告管理-链接管理
export function friendHandle (data) {
  return $http.post('/master/getFriendHandle', data, { isLogin: true })
}

// 网站管理-备用网址
export function backupUrl (data) {
  return $http.post('/master/spaceUrlList', data, { isLogin: true })
}
// 网站管理-备用网址-保存
export function backupUrlSave (data) {
  return $http.post('/master/spaceUrlOperate', data, { isLogin: true })
}
