import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/less/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/utils/filters'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import echarts from 'echarts'
import './assets/less/public.css'
import '@wangeditor/editor/dist/css/style.css'

// 全局公共组件
import plugins from '@/components/index'
Vue.use(plugins)

dayjs.locale('zh-cn')
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
// 生产环境阻止启动调试信息
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
